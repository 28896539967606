<template>
  <div :style="{ gap: data?.margin == null ? '12px' : `${data?.margin}px` }"
       class="flex overflow-x-auto ps-3 mb-3 width-full">
    <nuxt-link v-for="(image, index) in slider" :key="`grid-image-${index}`"
               :style="`min-width: ${image?.width}px`"
               :to="formattedSeoUrl(image)"
               @click.prevent="toAuthentication(image)">
      <niceone-image :src="image.image_url" class="rounded-md w-full"/>
    </nuxt-link>
  </div>
</template>

<script setup>
import {useAuth} from '@/composables/useAuth'
import {BANNER_TYPE_FOR_AUTH} from '@/constants'
import {formattedSeoUrl} from "@/utilities";

const props = defineProps({
  data: [Object],
  loading: {
    type: Boolean,
    default: false
  }
})

const slider = computed(() => {
  return props.data.data.filter((img) => {
    if (!useNuxtApp().$device.isDesktop) return true
    if (!img.for_mobile && useNuxtApp().$device.isDesktop) return true
    return false
  })
})

const {useRedirection} = useAuth()
const toAuthentication = (item) => {
  const type = parseInt(item?.type)
  if (type === BANNER_TYPE_FOR_AUTH) {
    useRedirection(item)
  }
}
</script>